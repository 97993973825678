import React from 'react';
import BreadcrumbSchema from '../../components/BreadcrumbSchema';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { FaLaptopCode } from 'react-icons/fa';
import ServiceTemplate from '../../components/service/ServiceTemplate';

const WebNew = ({ data }) => {
  const seoData = {
    title: 'Web広告運用｜奈良のホームページ制作なら｜インヴォルブ',
    description:
      'Google広告・SNS広告を活用し、ターゲットに的確にリーチ。データ分析をもとに広告運用を最適化し、費用対効果を最大化。無駄なく効率的に集客を促進します。',
    pageUrl: 'https://www.involve-in.jp/service/ads',
    ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
  };

  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    { name: 'サービス一覧', url: 'https://www.involve-in.jp/service/' },
    { name: 'Web広告運用', url: seoData.pageUrl },
  ];

  const works = data.allContentfulCaseStudy.nodes; // Contentful から施工事例を取得

  return (
    <>
      {/* ✅ 構造化パンくずデータを渡す */}
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <ServiceTemplate
        title="Web広告運用"
        subtitle="集客効果を最大化"
        icon={FaLaptopCode}
        description="Google広告・SNS広告を活用し、ターゲットに的確にリーチ。データ分析をもとに広告運用を最適化し、費用対効果を最大化。無駄なく効率的に集客を促進します。"
        seo={{
          title: 'Web広告運用｜奈良のホームページ制作なら｜インヴォルブ',
          description:
            'Google広告・SNS広告を活用し、ターゲットに的確にリーチ。データ分析をもとに広告運用を最適化し、費用対効果を最大化。無駄なく効率的に集客を促進します。',
          pageUrl: 'https://www.involve-in.jp/service/ads',
          ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
        }}
        breadcrumbs={[
          { name: 'ホーム', url: 'https://www.involve-in.jp/' },
          {
            name: 'サービス一覧',
            url: 'https://www.involve-in.jp/service/',
          },
          {
            name: 'Web広告運用',
            url: 'https://www.involve-in.jp/service/ads',
          },
        ]}
        benefits={[
          {
            title: '精密なターゲティング',
            description:
              'ユーザーの行動データを分析し、年齢・地域・興味関心などを細かく設定して最適なターゲットにアプローチ。',
          },
          {
            title: '運用データの最適化',
            description:
              'クリック率（CTR）やコンバージョン率（CVR）を分析し、広告文・クリエイティブ・入札戦略を随時改善。',
          },
          {
            title: '費用対効果の最大化',
            description:
              '無駄な広告費を削減し、限られた予算の中で最大の成果を出すための戦略的な運用を実施。',
          },
        ]}
        flow={[
          {
            title: 'ヒアリング・目標設定',
            duration: '1週間',
            description:
              '広告の目的（集客・認知拡大・CV獲得など）やターゲットを明確にし、最適な広告プランを設計。',
          },
          {
            title: 'キャンペーン設計・広告制作',
            duration: '1週間',
            description:
              'Google広告・SNS広告のキャンペーンを設計し、ターゲットに刺さる広告文やクリエイティブを制作。',
          },
          {
            title: '配信開始・データ分析',
            duration: '1週間',
            description:
              '広告を運用開始し、クリック率（CTR）・コンバージョン率（CVR）などのデータを随時モニタリング。',
          },
          {
            title: '運用最適化・改善',
            duration: '継続',
            description:
              '広告の成果を分析し、ターゲティングや入札戦略を調整しながら、費用対効果を最大化する運用を実施。',
          },
        ]}
        preparation={[
          {
            title: '広告の目的を明確に',
            description:
              '集客・認知拡大・問い合わせ獲得など、広告で達成したい目標を設定。',
          },
          {
            title: 'ターゲットの選定',
            description:
              '年齢・性別・地域・興味関心など、狙うべきユーザー層を決定。',
          },
          {
            title: '予算と配信期間の決定',
            description:
              '1ヶ月あたりの広告予算や、キャンペーンの配信期間を事前に設定。',
          },
          {
            title: 'クリエイティブの準備',
            description:
              '広告用の画像・動画・キャッチコピーを用意し、効果的な広告を作成。',
          },
        ]}
        works={works.map((work) => ({
          title: work.title,
          image:
            work.image && work.image.length > 0
              ? getImage(work.image[0].gatsbyImageData)
              : null, // 🔥 最初の画像のみ取得
        }))}
        price="¥300,000〜"
        cta="まずは無料相談"
      />
      {/* ✅ 表示用のパンくずは ServiceTemplate で管理 */}
    </>
  );
};

// GraphQL クエリ（Contentful の施工事例を取得）
export const query = graphql`
  query {
    allContentfulCaseStudy(sort: { publishDate: DESC }) {
      nodes {
        title
        slug
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            formats: [AUTO, WEBP]
            placeholder: BLURRED
          )
        }
      }
    }
  }
`;

export default WebNew;
